import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {path: '/'||'',component: () => import('@/views/home.vue') },
  {path: '/about',component: () => import('@/views/about.vue')},
  {path: '/projects',component: () => import('@/views/projects.vue')},
  {path: '/grants',component: () => import('@/views/grants.vue')},
  {path: '/research',component: () => import('@/views/research.vue')},
  {path: '/news',component: () => import('@/views/news.vue')},
]

const router = new VueRouter({
  routes
})

export default router
