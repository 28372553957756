module.exports = {
  language: {
    name: "English",
  },
  navTitle: {
    About: "关于",
    Projects: "项目",
    Research: "研究",
    News: "新闻",
    Blog: "博客",
  },
  aboutMissiontitle:
    "WEB3 风 险 资 本 专 注 投 资 web3.0 生 态 项 目，重 点 布 局 波 卡 生 态 和 去 中 心 化 金 融 DeFi ",
  homeBottomTitle: "风 险 资 本",
  // About页面
  aboutMissionTitle: `使命：投资应用去中心化协议的前沿尖端应用程式。`,
  aboutPassionTitle:
    "激情：WEB3.0，一个分散和公平的互联网，用户可以控制自己的数据、身份和命运。波卡是我们投资的旗舰项目。",
  ABOUT: "关 于",
  h3: "WEB3 风 险 资 本 认 为 相 信 长 期 滚 雪 球 式 的 投 资 轨 道，其中：",
  pOne: "WEB3.0 技 术 将 促 进 数 据 所 有 权 革 命。",
  pTow: "WEB3.0 理 念 将 继 续 推 动 平 权 行 动。",
  pTherr: "WEB3.0 网 络 将 加 速 降 低 信 任 成 本。",
  bodyTitle: "WEB3.0 到 底 是 什 么？",
  second: "贝 内 特",
  con:
    "胡 安 · 贝 内 特 , 协 议 实 验 室 的 创 始 人 兼 首 席 执 行 官 ， 讲 述 了 WEB3.0 的 愿 景 以 及 它 与 人 类 从 前 计 算 文 明 到 后 计 算 文 明 之 间 的 关 系。",
  textTitle: "Web 3.0 之 旅？",
  bodysecond: "加 文 伍 德",
  bottomCon:
    "WEB3 基 金 会 创 始 人 兼 总 裁 Gavin Wood 博 士 讨 论 了 WEB3.0 背 后 的 精 神 和 愿 景，并 提 出 将 WEB3.0 技 术 堆 栈 作 为 衡 量 我 们 进 步 的 一 种 方 法。",
  fourHead: "Web 3.0 技 术 堆 栈",
  
  fourL4: "支持协议可扩展的用户界面（“浏览器”）",
  fourL4detail1: " 堆栈的顶层，包括普通用户而非开发人员与一个或多个区块链进行交互的能力。",
  fourL4detail2: "支持协议可扩展的用户界面（“浏览器”）-- 一种程序，用户可使用该程序直接与区块链进行交互，而无需了解实现细节（就像开发人员可能的那样）。 示例包括Status，MetaMask或MyCrypto。",
  fourL3: "协议可扩展的开发人员API和语言",
  fourL3detail1: " 这是人类可读语言和库的层，允许开发人员以适当的抽象级别创建程序。",
  fourL3detail2: "协议可扩展的开发人员API和 语言-可以使用多种语言来开发应用程序而无需处理实际的字节码，例如Solidity和Vyper（以太坊），Plutus（卡尔达诺）和Rust（Substrate）。 此外，还有各种可用的框架，可以使开发与区块链进行交互的应用程序更加容易，例如ethers.js，WEB3.js和oo7.js。",

  fourTitle: "第二层协议",
  fourTitle_detail: "该层通过允许增加扩展性，加密消息传递和分布式计算等功能来增强第1层中列举的功能。",
  
  fourTitle: "第 二 层 协 议",
  fourOne: "状 体 通 道",
  fourTow:
    "可 信 计 算",

  fourTherr: "加 密 存 储",
  four: "密 集 计 算",
  fourFive: "分 布 式 密 文 管 理",
  fourSix: "预言机",
  fourDetail1: " 状态通道-区块链通过在主链上通过“打开”和“关闭”通道使节点彼此进行脱链通信来增加可伸缩性的一种方法，而不是仅写入初始和最终结果 每个状态转换都记录在链上。 例子包括比特币的闪电网络和以太坊的雷电网络。",
  fourDetail2: " 可信计算-Phala.网络在保存数据的同时保证智能合约的可靠执行秘密的。保密的合同在miner节点上运行，具有可信计算能力的硬件，保证了合同数据的保密性。",
  fourDetail3: " 加密存储-加密技术的用户以数学方式加密和解密静态（即存储在特定计算机上）和动态（即从一台计算机传输到另一台计算机）的数据。",
  fourDetail4: " 繁重的计算-提供一种方法，使计算可以在许多计算机之间传播，并证明计算正确执行。 例如，以太坊上的Golem和TrueBit。",
  fourDetail5: " 分布式秘密管理-仅允许授权方访问信息，包括复杂的场景，例如“解密此信息要求所有六个签名者都使用其密钥”或“ 7个签名者中的任何5个必须同意”。 例如平价秘密商店。",
  fourDetail6: " 预言机-一种将脱链数据（例如天气结果或股票价格）注入到区块链中的方法，通常供智能合约使用。",
  fourL1TitleDetail: "该层提供了分发数据并与数据交互的能力。",
  fourL11: " 零/低信任度交互协议（比特币，以太坊，平行链）",
  fourL11Detail: " 零/低信任度交互协议-一种协议，描述了不同节点之间如何交互以及如何信任来自每个节点的计算和信息。 大多数加密货币，例如比特币和ZCash，都满足零信任/低信任度交互协议的定义-它们描述了节点遵循该协议所必需的规则。",
  fourL12: "数据分发协议",
  fourL12Detail: "数据分发协议-描述分散系统中各个节点之间如何分发和通信数据的协议。 示例包括IPFS，Swarm和BigchainDB。",
  fourL13: "即时数据发布/订阅消息传递",
  fourL13Detail: " 即时数据发布/订阅消息传递-协议，描述了如何传达不打算永久存储的数据（例如状态更新）以及如何使节点知道其存在。 示例包括Status,MetaMask或者MyCrypto.",
  fourL0TitleDetail: "这是WEB3技术堆栈的基础，该技术堆栈包括节点如何通信以及如何在最低级别对其进行编程。",
  fourL01: "零信任度/低信任度元协议（Polkadot）",
  fourL01Detail: " 零信任度/低信任度交互平台（共享安全性）-一种实现零信任度/低信任度交互协议的平台，以允许所有参与的成员彼此共享安全性。 波卡就是一个例子。",
  fourL02: "对等（P2P）互联网覆盖协议",
  fourL02Detail: " 对等（p2p）互联网覆盖协议-一种网络套件，允许节点以分散方式进行通信。",
  fourL03: "与平台无关的语言",
  fourL03Detail: " 与平台无关的计算描述语言-一种在不同的物理平台（架构，OS等）上执行相同程序的方式。 示例包括EVM（以太坊），UTXO（比特币）和Wasm（Polkadot）。",

  //项目
  projects: {
    title:
      "WEB3 VC 通 过 早 期 投 资、技 术 研 发 支 持、市 场 宣 发、社 群 运 营 推 广、市 场 调 研 等 多 种 方 式 推 动 WEB3.0 的 发 展。",
    PROJECT: "项 目",
    check: "查 看 文 章",
    titles: {
      one: "Polkadot: WEB3 风 险 资 本 重 点 投 资 项 目",
      tow: "Kusama:  WEB3 风 险 资 本 早 期 投 资 项 目",
      // three: "ChainX: WEB3 风 险 资 本 前 期 投 资 项 目",
      four: "Phala: WEB3 风 险 资 本 长 期 投 资 项 目",
      five: "TransX: WEB3 风 险 资 本 战 略 投 资 项 目",
      six: "Listen: WEB3 风 险 资 本 战 略 投 资 项 目",
      seven: "IBO: WEB3 风 险 资 本 战 略 投 资 项 目",
      eight: "DICO: WEB3 风 险 资 本 战 略 投 资 项 目",
      nine: "PolkaWorld",
      tp: "TP 钱 包: 让 区 块 链 随 处 发 生，多 链 钱 包，安 全 易 用，区 块 链 入 口",
      ogx: "OGX: 去 中 心 化 合 成 资 产 发 行 与 交 易 协 议",
      ins3: "INS3：下 一 代 完 全 去 中 心 化 的 保 险 协 议"
    },
    text: {
      one:
        "Polkadot 是 一 个 定 位 打 破 区 块 链 行 业 天 花 板 的 项 目，跨 链 协 议 是 表 象，解 决 区 块 链 扩 容 才 是 根 本，Polkadot 具 有 能 使 区 块 链 再 次 伟 大 的 潜 力。",
      tow:
        "Kusama 是 一 个 可 伸 缩 的 开 发 网 络，它 建 立 在 与 Polkadot 几 乎 相 同 的 代 码 库 上。 它 是 为 那 些 想 快 速 发 展 和 创 新 的 团 队 设 计 的。",
      // three:
      //   "破 坏 区 块 链 之 间 的 资 产 壁 垒，构 建 多 币 种 融 合 的 公 链 生 态。",
      four:
        "Phala:WEB3 风 险 资 本 资 金 长 期 投 资 项 目 通 过 可 信 计 算 实 现 锁 链 保 密。",
      five:
        "数 字 货 币 聚 合 支 付 平 台，您 的 全 球 区 块 链 支 付 “Paypal”。",
      six:
        "基 于 WEB3 技 术（数 据 分 散 存 储 和 跨 链 区 块 链 技 术）的 语 音 社 交 网 络。",
      seven:
        "IBO:WEB3 风 险 资 本 战 略 投 资 项 目 SMI 去 中 心 化 交 易，抽 象 化 代 币 上 架、平 台 代 币 透 明 焚 烧 和 连 锁 治 理 的 功 能，并 逐 步 过 渡 到 去 中 心 化 交 易。",
      eight:
        "Polkadot 生 态 中 的 分 散 式 筹 资 平 台，使 用 严 格 的 分 散 式 KYC 审 核 和 DAO 管 理 募 集 资 金。",
      nine: "波 卡 生 态 中 最 深 刻、最 具 影 响 力 的 媒 体 平 台。",
      tp: "去 中 心 化 钱 包 将 是 未 来 整 个 区 块 链 的 流 量 入 口、也 是 整 个 DeFi 的 基 础 设 施。",
      ogx: "资 产 合 成 和 发 行、可 以 合 成 众 多 链 上 资 产、并 可 以 构 建 现 货 交 易、期 货 交 易 等 多 种 应 用。",
      ins3: "INS3.FINANCE 是一个完全去中心化保险协议：DeFi + NFT + 预言机 + 跨链模式的一个重大加密保险生态的金融创新。"
    },
  },
  //研究
  research: {
    title: "研 究",
    content:
      "WEB3 风 险 资 本 的 研 究 团 队，主 要 寻 找 WEB3.0 技 术 高 边 疆 在 现 实 生 活 中 落 地 的 财 富 机 会，并 思 考 商 业、道 德、法 律 等 问 题 的 再 平 衡。",
    span: "访 问 研 究 维 基",
    right: {
      one:
        " WEB3 风 险 资 本 的 研 究 团 队 是 全 球 分 布 式 办 公，主 要 分 布 于 深 圳、杭 州、成 都、昆 明、新 加 坡、达 拉 斯、东 京 等 地。",
      tow: "我 们 的 研 究 集 中 在 以 下 领 域：",
      three: "可 信 计 算 和 隐 私 保 护 算 法。",
      four: "去 中 心 化 区 块 链 网 络 与 全 球 安 全 的 p2p 支 付 网 络。",
      five: "下 一 个 社 会 网 络 和 群 组 DAO 模 式。",
      six: "监 管 分 散 筹 资 模 式 和 下 一 代 加 密 资 产 交 易 平 台。",
      seven: "链 上 去 中 心 化 的 无 抵 押 小 额 贷 款。"
    },
    papers: "论 文",
    blogs: "博 客",
    PapersTitle: {
      one: "隐 私 数 据 交 换 协 议",
      tow: "数 字 货 币 聚 合 支 付 平 台",
      three: "基 于 WEB3 技 术 的 语 音 社 交 网 络",
      four: "半 去 中 心 化 交 易 所",
      five: "去 中 心 化 融 资 平 台",
    },
    bottom: {
      head: "会 见 研 究 团 队",
      title: "彼 得·查 班",
      content: "首 席 技 术 官",
    },
    click:'点击查看'
  },
  //新闻
  news: {
    title: "WEB3 风 险 资 本 公 司 和 Listen 项 目 已 经 战 略 合 作",
    p: "2 0 2 0 年 4 月 2 3 日，星 期 三",
    newsTitle: "新 闻",
    forDate:{
        ins3: "2 0 2 1 年 4 月 1 日，星 期 四",
        one:'2 0 2 0 年 4 月 2 3 日，星 期 三',
        tow:'2 0 2 0 年 3 月 2 2 日，星 期 二',
        three:'2 0 2 0 年 3 月 1 7 日，星 期 四',
        four:'2 0 2 0 年 3 月 1 2 日，星 期 四',
        five:'2 0 2 0 年 2 月 1 1 日，星 期 一'
    },
    forTitle:{
      ins3: "WEB3 风 险 资 本 成 功 投 资 去 中 心 化 保 险 协 议 INS3.FINANCE",
        one:'WEB3 风 险 资 本 公 司 和 Listen 项 目 已 经 战 略 合 作',
        tow:'WEB3 风 险 资 本 Phala 网 络',
        three:'WEB3 风 险 资 本 融 资 成 功 一 周',
        four:'WEB3 风 险 资 本 开 始 募 集',
        five:'WEB3 风 险 资 本 成 功 注 册',
    },
    click:'点击查看'
  },
};
