<template>
  <div>
    <v-progress-linear
      indeterminate
      v-if="isLoading"
      color="white"
    ></v-progress-linear>
    <div
      v-if="!isLoading"
      style="height: 4px; width: 100%; background: #000"
    ></div>
    <header
      id="nav"
      class="row-c pc"
      :style="{ background: show ? '#000' : 'transparent' }"
    >
      <div class="content row-b">
        <div class="left" @click="toHme">
          <a href="#/"><img src="@/assets/img/logo.png" alt="" /></a>
        </div>
        <div class="lang">
          <span class="lang-title">{{ title }}</span>
          <select v-model="formData.value" @change="changeLang" class="lval">
            <option value="语言" class="font-size14">CN</option>
            <option value="language" class="font-size14">EN</option>
          </select>
          <img
            src="@/assets/img/sign.png"
            alt=""
            class="sign-icon"
            @click="changeLang"
          />
          <div class="list">
            <div class="option"></div>
          </div>
        </div>
        <div
          class="right row-c"
          @click="change"
          :style="{
            'background-size': '100% 100%',
          }"
        >
          <div class="all row-b animated fadeInLeft">
            <a
              href="#/about"
              :class="[nPage == 'about' ? 'now' : '']"
              class="item row-c"
              >{{ $t("navTitle.About") }}</a
            >
            <a
              href="#/projects"
              :class="[nPage == 'projects' ? 'now' : '']"
              class="item row-c"
              >{{ $t("navTitle.Projects") }}</a
            >
            <!-- <a href="#/grants" :class="[nPage=='grants'?'now':'']" class="item row-c">Grants</a> -->
            <a
              href="#/research"
              :class="[nPage == 'research' ? 'now' : '']"
              class="item row-c"
              >{{ $t("navTitle.Research") }}</a
            >
            <a
              href="#/news"
              :class="[nPage == 'news' ? 'now' : '']"
              class="item row-c"
              >{{ $t("navTitle.News") }}</a
            >
            <!-- <a href="https://web3.bamboohr.com/jobs/" class="item row-c">Careers</a> -->
            <a href="https://hi-20417.medium.com/" class="item row-c">{{
              $t("navTitle.Blog")
            }}</a>
          </div>
        </div>
      </div>
    </header>

    <!-- 移动端 -->
    <v-app-bar app color="#000" dark class="mobile">
      <div class="d-flex align-center" @click="toHme">
        <a href="#/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="@/assets/img/logo.png"
            transition="scale-transition"
            width="40"
          />
        </a>
      </div>

      <div class="lang">
        <span class="lang-title">{{ title }}</span>
        <select v-model="formData.value" @change="changeLang" class="lval">
          <option value="语言" class="font-size14">CN</option>
          <option value="language" class="font-size14">EN</option>
        </select>
        <img
          src="@/assets/img/sign.png"
          alt=""
          class="pt-3"
          @click="changeLang"
        />
        <div class="list">
          <div class="option"></div>
        </div>
      </div>

      <v-spacer></v-spacer>
      <v-btn icon @click.stop="rightDrawer = !rightDrawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="rightDrawer"
      :right="right"
      temporary
      fixed
      class="mobile"
    >
      <div @click="change">
        <v-list class="my-drawer" nav>
          <v-list-item
            router
            to="/about"
            :class="[nPage == 'about' ? 'now' : '']"
            class="item row-c"
            >{{ $t("navTitle.About") }}</v-list-item
          >
          <v-list-item
            router
            to="/projects"
            :class="[nPage == 'projects' ? 'now' : '']"
            class="item row-c"
            >{{ $t("navTitle.Projects") }}</v-list-item
          >

          <v-list-item
            router
            to="/research"
            :class="[nPage == 'research' ? 'now' : '']"
            class="item row-c"
            >{{ $t("navTitle.Research") }}</v-list-item
          >
          <v-list-item
            router
            to="/news"
            :class="[nPage == 'news' ? 'now' : '']"
            class="item row-c"
            >{{ $t("navTitle.News") }}</v-list-item
          >
          <v-list-item
            exact
            target="_blanck"
            href="https://hi-20417.medium.com/"
            class="item row-c"
          >
            {{ $t("navTitle.Blog") }}</v-list-item
          >
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      right: true,
      rightDrawer: false,

      showClass: false,
      title: "语言：",
      lang: "en",
      langName: "language",
      nPage: "123",
      show: true,
      // mobile: false, // 当前是否移动端

      btn: require("../assets/img/list1.png"),
      formData: {
        value: "language",
      },
    };
  },
  computed: {
    ...mapState(["isLoading"]),
    crePath() {
      // 路径实时获取
      let path = this.$route.path;
      path == "/home" || path == "/" ? (this.show = false) : (this.show = true);
      this.nPage = path.slice(1);
      return path;
    },
    width() {
      return document.documentElement.clientWidth;
    },
  },
  created() {
    this.changeLang();
    // if (document.documentElement.clientWidth < 940) {
    //   this.mobile = true;
    //   this.show2 = false;
    // }
  },
  watch: {
    crePath(n, o) {
      //路径监听赋值
      console.log(n);
      this.nPage = n.slice(1);
    },
    width(n, o) {
      console.log(n, o);
      if (n < 940) {
        this.mobile = true;
        this.show2 = false;
      }
    },
    show2(n, o) {
      if (n) {
        document.body.style.overflow = "hidden";
        document.documentElement.style.overflow = "hidden";
        this.btn = require("../assets/img/cld.png");
      } else {
        document.body.style.overflow = "auto";
        document.documentElement.style.overflow = "auto";
        this.btn = require("../assets/img/list1.png");
      }
    },
  },
  methods: {
    toHme() {
      document.title = "Web3 Venture Capital";
    },
    change(e) {
      // if (this.mobile) {
      //   this.show2 = !this.show2;
      //   return;
      // }
      if (!e.target.text) return;
      this.nPage = e.target.text.toLowerCase();
      console.log(this.nPage, 333);
      document.title = this.nPage;
    },
    // 切换中英文
    changeLang() {
      let temp = "";
      let locale = localStorage.getItem("language") || "zh";
      let value = this.formData.value;
      if (value == "语言") {
        temp = "zh";
        this.showClass = false;
        this.title = "语言：";
      } else {
        temp = "en";
        this.showClass = true;
        this.title = "language：";
      }
      this.$i18n.locale = temp; //改变当前语言
      localStorage.language = temp;
    },
  },
};
</script>

<style lang="scss" scoped>
#nav .theme--dark.v-app-bar.v-toolbar.v-sheet {
  background: transparent;
}
.lang {
  // min-width: 15vw;
  position: relative;
  left: -15vh;
  top: 1vh;
}
.lang > .lang-title {
  font-size: 14px;
  color: #fff;
}
.lang > .lval {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}
.sign-icon {
  width: 0.8vw;
  margin-top: 1.5%;
}
.lang > .list {
  width: 100%;
  position: absolute;
  top: 8vh;
  left: 0;
}
.lang .option {
  width: 100%;
}
.mobile {
  display: none;
}
@media screen and (max-width: 940px) {
  // 移动设备
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
  .lang {
    left: 1vh;
    top: 0;
  }
}
#nav {
  width: 100%;
  height: 12vh;

  padding: 1.5vh 0;
  box-sizing: border-box;
  z-index: 99;
  > .content {
    // background: #f00;
    width: 75vw;
    height: 100%;
    position: relative;
    > .left {
      width: 4vw;
      height: 100%;
      // background: #ff0;
      transform: scale(0.5);
      > a > img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    > .right {
      width: 36vw;
      height: 100%;
      font-size: 17px;
      // background: #0f0;
      > .all {
        width: 100%;
      }
      .item {
        color: #fff;
        height: 100%;
        cursor: pointer;
        // background: #0ff;
        position: relative;
        font-size: 1.05em;
      }
      .item::after {
        content: "";
        display: block;
        width: 0%;
        height: 4px;
        background: #fff;
        position: absolute;
        left: 0;
        top: 120%;
        transition: all ease 0.3s;
      }
      .item:hover::after {
        width: 100%;
      }
      .now::after {
        width: 100%;
      }
    }
  }
}
</style>