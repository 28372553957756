<template>
  <footer class="row-c">
    <div class="content col-b">
      <div class="top row-b_">
        <div class="logo row-c">logo</div>
        <div class="menu col_">
          <a href="#/about">About</a>
          <a href="#/projects">Projects</a>
          <a href="#/grants">Grants</a>
          <a href="#/research">Research</a>
          <a href="#/news">News</a>
          <a href="https://web3.bamboohr.com/jobs/">Careers</a>
          <a href="https://medium.com/web3foundation/">Blog</a>
        </div>
        <div class="link row-b">
          <div class="i"></div>
          <div class="i"></div>
          <div class="i"></div>
          <div class="i"></div>
          <div class="i"></div>
          <div class="i"></div>
        </div>
      </div>
      <div class="down row-b">
        <div class="left row-b">
          <div class="item"></div>
          <div class="item"></div>
          <div class="item"></div>
        </div>
        <div class="right col-b">
          <div class="up row-b">
            <a href="">Security Report</a>
            <a href=""> Privacy and Cookies </a>
            <a href="">Cookie Settings</a>
          </div>
          <div class="down row-e">
            © 2020 Web3 Foundation, All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
    data() {
        return {
            show: true
        }
    },
    computed: {
        crePath() { // 路径实时获取
            let path = this.$route.path
            console.log(path);
            path =='/home'||path=='/'?this.show=false:this.show=true;
            return path
        }
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 940px) { // 移动设备
  footer {
    padding: 2vh 4vw !important;
    height: auto !important;
    >.content {
      width: 100% !important;
      height: auto !important;
      // background: #eee;
      flex-direction: column !important;
      >.top {
        width: 90vw !important;
        // background: #aaa;
        flex-direction: column !important;
        >.logo {
          width: 30vw !important;
          height: 25vh !important;
        }
        >.menu {margin: 2vh 0;}
        >.link {
          width: 100% !important;
          >.i {
            width: 9vw !important;
            height: 9vw !important;
          }
        }
      }
      >.down {
        >div {width: 90vw !important;}
        .left {justify-content: flex-start !important;}
        .item {width: 20vw !important;margin: 2vh 0;}
        .item+.item {margin-left: 8vw;}
        .up {
          justify-content: flex-start !important;
          a {margin-right: 4vw;}
        }
      }
    }
  }
}
footer {
  width: 100%;
  height: 60vh;
  background: url("../assets/img/footBg.jpg") no-repeat;
  background-size: cover;
  padding: 7vh 0;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  overflow: hidden;
  > .content {
    width: 75vw;
    height: 100%;
    // background: #eee;
    > .top {
      width: 100%;
      height: 50%;
    //   background: #ddd;
      > .logo {
        width: 6vw;
        height: 80%;
        background: #ccc;
        > img {
          width: 100%;
        }
      }
      > .menu {
        width: 13vw;
        height: 80%;
        // background: #bbb;
        padding-right: 5vw;
        > a {
          color: #fff;
          font-size: 1.2em;
          margin: 0.6vh 0;
          margin-right: 2vw;
        }
      }
      > .link {
        width: 30vw;
        height: 50%;
        // background: #aaa;
        > .i {
          width: 2.5vw;
          height: 2.5vw;
          background: #999;
          cursor: pointer;
          transition: all ease 0.1s;
        }
        > .i:hover {
          transform: scale(1.5, 1.5);
        }
      }
    }
    >.down {
      width: 100%;
    //   background: #888;
      >.left {
        width: 37%;
        // background: #0ff;
        >.item {
          width: 6vw;
          height: 6vh;
          background: #7F7F7F;
        }
      }
      >.right {
          width: 20vw;
        //   background: #666;
          font-size: 14px;
          >.up {
              width: 100%;
            //   background: #fff;
          }
        color: #7F7F7F;
        div {width: 100%;}
        a{color: #7F7F7F;font-size: 14px;}
        a:hover{color: #eee;}
      }
    }
  }
}
</style>