<template>
  <header
    id="nav"
    class="row-c"
    :style="{ background: show ? '#000' : 'transparent' }"
  >
    <div class="content row-b">
      <div class="left">
        <a href="#/"><img src="@/assets/img/logo.png" alt="" /></a>
      </div>
      <div class="lang row ">
          <span class="lang-title">{{ title }}</span>
          <select
            v-model="formData.value"
            @change="changeLang"
            class="lval" >
            <option value="语言" class="font-size14">CN</option>
            <option value="language" class="font-size14">EN</option>
          </select>
          <img src="@/assets/img/sign.png" alt="" class="sign-icon"  
            @click="changeLang"
          />
          <div class="list">
            <div class="option"></div>
          </div>
        </div>
      <div
        class="right row-c"
        @click="change"
        :style="{
          background: mobile ? 'url(' + btn + ') no-repeat' : '',
          'background-size': '100% 100%',
        }"
      >
        <div class="all row-b animated fadeInLeft" v-show="show2">
          <a
            href="#/about"
            :class="[nPage == 'about' ? 'now' : '']"
            class="item row-c"
            >{{ $t("navTitle.About") }}</a
          >
          <a
            href="#/projects"
            :class="[nPage == 'projects' ? 'now' : '']"
            class="item row-c"
            >{{ $t("navTitle.Projects") }}</a
          >
          <!-- <a href="#/grants" :class="[nPage=='grants'?'now':'']" class="item row-c">Grants</a> -->
          <a
            href="#/research"
            :class="[nPage == 'research' ? 'now' : '']"
            class="item row-c"
            >{{ $t("navTitle.Research") }}</a
          >
          <a
            href="#/news"
            :class="[nPage == 'news' ? 'now' : '']"
            class="item row-c"
            >{{ $t("navTitle.News") }}</a
          >
          <!-- <a href="https://web3.bamboohr.com/jobs/" class="item row-c">Careers</a> -->
          <a href="https://medium.com/web3foundation/" class="item row-c">{{
            $t("navTitle.Blog")
          }}</a>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      showClass:false,
      title: "语言：",
      lang: "en",
      langName: "language",
      nPage: "123",
      show: true,
      mobile: false, // 当前是否移动端
      show2: true, // 是否显示列表
      btn: require("../assets/img/list1.png"),
      formData: {
        value: "language",
      },
    };
  },
  computed: {
    crePath() {
      // 路径实时获取
      let path = this.$route.path;
      path == "/home" || path == "/" ? (this.show = false) : (this.show = true);
      this.nPage = path.slice(1);
      return path;
    },
    width() {
      return document.documentElement.clientWidth;
    },
  },
  created() {
    this.changeLang();
    if (document.documentElement.clientWidth < 940) {
      this.mobile = true;
      this.show2 = false;
    }
  },
  watch: {
    crePath(n, o) {
      //路径监听赋值
      console.log(n);
      this.nPage = n.slice(1);
    },
    width(n, o) {
      console.log(n, o);
      if (n < 940) {
        this.mobile = true;
        this.show2 = false;
      }
    },
    show2(n, o) {
      if (n) {
        document.body.style.overflow = "hidden";
        document.documentElement.style.overflow = "hidden";
        this.btn = require("../assets/img/cld.png");
      } else {
        document.body.style.overflow = "auto";
        document.documentElement.style.overflow = "auto";
        this.btn = require("../assets/img/list1.png");
      }
    },
  },
  methods: {
    change(e) {
      if (this.mobile) {
        this.show2 = !this.show2;
        return;
      }
      this.nPage = e.target.text.toLowerCase();
      console.log(this.nPage);
    },
    // 切换中英文
    changeLang() {
      let temp = "";
      let locale = localStorage.getItem("language") || "zh";
      let value = this.formData.value;
      if (value == "语言") {
        temp = "zh";
        this.showClass = false
        this.title = '语言：'
      } else {
        temp = "en";
         this.showClass = true
        this.title = 'language：'
      }
      this.$i18n.locale = temp; //改变当前语言
      localStorage.language = temp;
    },
  },
};
</script>
<style lang="less" scoped>
.lang {
  // min-width: 15vw;
  position: relative;
  left: -15vh;
  top: 1vh;
}
.lang > .lang-title {
  font-size: .8vw;
  color: #fff;
}
.lang > .lval {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}
.sign-icon {
  width: .8vw;
  margin-top: 1.5%;
}
.lang > .list {
  width: 100%;
  position: absolute;
  top: 8vh;
  left: 0;
}
.lang .option {
  width: 100%;
}
@media screen and (max-width: 940px) {
  // 移动设备
  html,
  :root,
  body {
    font-size: 10px !important;
  }
  #nav {
    height: 10vh !important;
    padding: 1vh 5vw !important;
    // background: #0ff;
    position: absolute !important;
    left: 0;
    > .content {
      position: relative;
      width: 100% !important;
      // background: #0ff;
      > .left {
        width: 25vw !important;
        max-height: 4rem;
      }
      > .right {
        width: 9vw !important;
        height: 9vw !important;
        background-size: 100% 100% !important;
        transition: all ease 0.5s;
        > .all {
          width: 100%;
          height: 100%;
          position: fixed;
          left: 0;
          top: 10vh;
          z-index: 100%;
          background: #fff;
          padding-left: 6vw;
          padding-top: 2vh;
          flex-direction: column !important;
          justify-content: start !important;
          align-items: flex-start !important;
          flex-wrap: nowrap;
          > .item {
            height: auto !important;
            font-size: 1.6rem !important;
            margin: 0.2rem 0;
            color: #000;
          }
        }
      }
      > .right:target {
        background: url("../assets/img/cld.png") no-repeat;
      }
    }
  }
}
#nav {
  width: 100%;
  height: 12vh;
  top: 0;
  left: 0;
  position: absolute;
  padding: 1.5vh 0;
  box-sizing: border-box;
  z-index: 99;
  > .content {
    // background: #f00;
    width: 75vw;
    height: 100%;
    position: relative;
    > .left {
      width: 4vw;
      height: 100%;
      // background: #ff0;
      transform: scale(0.5);
      > a>img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    > .right {
      width: 36vw;
      height: 100%;
      font-size: 17px;
      // background: #0f0;
      > .all {
        width: 100%;
      }
      .item {
        color: #fff;
        height: 100%;
        cursor: pointer;
        // background: #0ff;
        position: relative;
        font-size: 1.05em;
      }
      .item::after {
        content: "";
        display: block;
        width: 0%;
        height: 4px;
        background: #fff;
        position: absolute;
        left: 0;
        top: 120%;
        transition: all ease 0.3s;
      }
      .item:hover::after {
        width: 100%;
      }
      .now::after {
        width: 100%;
      }
    }
  }
}
</style>