<template>
  <v-app id="app" data-title="Web3 Venture Capital">
    <Header />
    <v-main>
      <router-view />
    </v-main>

    <!-- <more v-if="show"></more> -->
    <!-- <foot v-if="show"></foot> -->
    <!-- <div v-if="show" class="nbsp"></div> -->
  </v-app>
</template>
<script>
import navigation from "@/components/nav.vue";
import Header from "@/components/header.vue";
import foot from "@/components/footer.vue";
import more from "@/components/more.vue";

export default {
  components: {
    Header,
    navigation,
    // foot,
    more,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    var el = document.getElementById("loader");
    if (el) {
      el.style.display = "none";
    }
  },
  computed: {
    crePath() {
      //实时执行
      let path = this.$route.path;
      console.log(path);
      path == "/home" || path == "/" ? (this.show = false) : (this.show = true);
      return path;
    },
  },
  watch: {
    crePath(n, o) {
      //路径改变执行
      console.info(n, this.show);
    },
  },
};
</script>
<style lang="less">
@media screen and (max-width: 940px) {
  // 移动设备
  .nbsp {
    height: 80vh !important;
  }
  #app {
    // width: 90vw !important;
  }
}

#app {
  // width: 75vw;
  min-height: 100vh;
  margin: 0 auto;
  background: #000000;
  color: #ffffff;
  @media (min-width: 1904px) {
    .container {
      max-width: 1385px;
    }
  }
}
.nbsp {
  width: 100%;
  height: 60vh;
}
</style>
