import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/why-flex.min.css'
import '@/assets/css/index.css'
import '@/assets/css/animate.min.css'
import VueI18n from "vue-i18n";
import Aos from "aos";
import "../node_modules/aos/dist/aos.css";
import vuetify from './plugins/vuetify';


Vue.use(Aos)
Vue.use(VueI18n);
Vue.config.productionTip = false
Aos.init()

// 设置浏览器标题
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
// 中英文翻译
const i18n = new VueI18n({
  locale: localStorage.getItem("language") || "zh", //使用localStorage缓存到本地，当下次使用时可默认当前使用语言
  messages: {
    zh: require("./common/lang/zh"),
    en: require("./common/lang/en")
  }
});
// 设置路由切换动画
router.beforeEach((to, from, next) => {
  store.dispatch('onLoading', true);
  console.log(store.state.isLoading);
  next();
});
router.afterEach((to, from) => {

  store.dispatch('onLoading', false);
  console.log(store.state.isLoading);

});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')