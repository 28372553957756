module.exports = {
  language: {
    name: "中文",
  },
  navTitle: {
    About: "About",
    Projects: "Projects",
    Research: "Research",
    News: "News",
    Blog: "Blog",
  },
  aboutMissiontitle: "WEB3 Venture Capital investment is dedicated to investing in WEB3.0 ecological projects, focusing on the Polkadot ecological and decentralized financial DeFi",
  homeBottomTitle: "VENTURE iNVESTMENT",
  // About页面
  aboutMissionTitle: "Mission： Invest cutting-edge applications for decentralized web software protocols.",
  aboutPassionTitle: "Passion： Web 3.0, a decentralized and fair internet where users control their own data, identity and destiny. Polkadot is our invested flagship project.",
  ABOUT: "ABOUT",
  h3: "WEB3 Venture Capital believes in a long snowballing investment track where：",
  pOne: "WEB3 technology will facilitate Data Ownership Revolution. ",
  pTow: "WEB3 technology will continue the affirmative action.",
  pTherr: "WEB3 technology will accelerate the reduction of trust costs.",
  bodyTitle: "What Exactly is Web 3.0?",
  second: "Juan Benet",
  con: "Juan Benet, founder and chief executive of Protocol Labs, talks about the Web 3.0 vision and how it relates to humanity going from a pre-computing civilization to a post-computing civilization.",
  textTitle: "The Journey to Web 3.0?",
  bodysecond: "Gavin Wood",
  bottomCon: "WEB3 Foundation founder and President Dr. Gavin Wood discusses the ethos and vision behind Web 3.0 and proposes the Web 3.0 Technology Stack as a way to measure our progress.",
  fourHead: "Web 3.0 Technology Stack",

  fourL4: " Protocol-extensible user-interface cradle (”browser”)",
  fourL4detail1: " The top level of the stack, this includes the ability for a general user - not developer - to interact with one or more blockchains.",
  fourL4detail2: "Protocol-extensible user-interface cradle (\"browser\") - A program which a user would use to interact directly with the blockchain without needing to know implementation details (as a developer might). Examples would include Status, MetaMask, or MyCrypto.",
  fourL3: " Protocol-extensible developer APIs &amp; languages",
  fourL3detail1: "  This is the layer of human-readable languages and libraries that allow developers to create programs at the proper level of abstraction.",
  fourL3detail2: "Protocol-extensible developer APIs &amp; languages - There are a variety of languages which can be used to develop applications without dealing with actual bytecode, such as Solidity and Vyper (Ethereum), Plutus (Cardano), and Rust (Substrate). Additionally, there are a variety of frameworks available to make it easier to develop applications interacting with a blockchain, such as ethers.js, WEB3.js, and oo7.js.",

  fourTitle: "Second layer protocols",
  fourTitle_detail: " This layer enhances the capabilities enumerated in Layer 1, by allowing functionality such as increased scaling, encrypted messaging, and distributed computing.",
  fourOne: "State Channels",
  fourTow: "Trusted Computation",
  fourTow1: "Trusted Computation： ",
  fourTherr: "Encrypted storage",
  four: "Heavy computation",
  fourFive: "Distributed secret management",
  fourSix: "Oracles",
  fourDetail1: " State channels - A way for a blockchain to increase scalability by having nodes communicate with each other off-chain, by \"opening\" and \"closing\" channels on the main chain, and writing only initial and final results, instead of each state transition being recorded on the chain. Examples include Bitcoin's Lightning Network and Ethereum's Raiden Network.",
  fourDetail2: " Trusted Computation - Phala.Network guarantees the reliable execution of smart contracts whilekeeping the data secretly.Confidential contracts run in miner nodes with Trusted Computing capablehardwares, which guarantees the secrecy of the contract data.",
  fourDetail3: " Encrypted storage - The user of cryptography to mathematically encrypt and decrypt data, both at-rest (i.e., stored on a specific computer) and in-motion (i.e., being transferred from one computer to another).",
  fourDetail4: " Heavy computation - Providing a way to allow computation to be spread amongst many computers as well as prove that the computations were performed correctly. Examples of this include Golem and TrueBit on Ethereum.",
  fourDetail5: " Distributed secret management - Allowing information to be accessed only by authorized parties, including complex scenarios such as \"decrypting this information requires all six signers to use their key\" or \"any 5 of 7 signers must agree\". Examples include Parity Secret Store.",
  fourDetail6: " Oracles - A way to inject off-chain data (such as weather results or stock prices) onto a blockchain, generally for use by smart contracts.",
  fourL1TitleDetail: "This layer provides the ability to distribute and interact with data.",
  fourL11: " Zero/low trust interaction protocols (Bitcoin, Ethereum, parachains)",
  fourL11Detail: " Zero/low trust interaction protocols - A protocol describing how different nodes can interact with each other and trust computation and information coming from each of them. Most cryptocurrencies, such as Bitcoin and ZCash, meet the definition of a Zero/low trust interaction protocol - they describe the rules necessary for a node to follow to participate in the protocol.",
  fourL12: "Data distribution protocols",
  fourL12Detail: " Data distribution protocols - Protocols describing how data is distributed and communicated amongst the various nodes in a decentralized system. Examples include IPFS, Swarm and BigchainDB.",
  fourL13: " Transient data pub/sub messaging",
  fourL13Detail: " Transient data pub/sub messaging - Protocols describing how data which is not meant to be permanently stored, such as status updates, is communicated and how nodes are made aware of its presence. Examples include Whisper and Matrix.",
  fourL0TitleDetail: " This is the foundation of the WEB3 technology stack, consisting of how nodes communicate and how they can be programmed at the lowest level.",
  fourL01: "  Zero/low trust metaprotocolos (Polkadot)",
  fourL01Detail: " Zero/low trust interaction platforms (shared security) - A platform implementing a zero/low trust interaction protocol to allow all participating members to share security with each other. Polkadot is an example of this.",
  fourL02: " Peer-to-peer (p2p) internet overlay protocols",
  fourL02Detail: "  Peer-to-peer (p2p) internet overlay protocols - A networking suite which allows nodes to communicate in a decentralized way.",
  fourL03: "  Platform neutral language",
  fourL03Detail: "  Platform-neutral computation description language - A way to execute the same program on different physical platforms (architectures, OSes, etc.). Examples include EVM (Ethereum), UTXOs (Bitcoin), and Wasm (Polkadot).",

  //项目
  projects: {
    title: "WEB3 VC promotes the development of WEB3.0 through early investment, technical research and development support, market promotion, community operation promotion, market research and other methods.",
    PROJECT: "PROJECT",

    titles: {
      one: "Polkadot：WEB3 Venture Capital key investment projects",
      tow: "Kusama: WEB3 Venture Capital early stage investment project",
      // three: "ChainX: WEB3 Venture Capital early stage investment project",
      four: "Phala:WEB3 Venture Capital long-term investment projects",
      five: "TransX: WEB3 Venture Capital Strategic Investment projects",
      six: "Listen: WEB3 Venture Capital Strategic Investment projects",
      seven: "IBO: WEB3 Venture Capital Strategic Investment projects",
      eight: "DICO: WEB3 Venture Capital Strategic Investment projects",
      nine: "PolkaWorld",
      tp: "TokenPocket: Make Blockchain Happen Everywhere, Multi-chain, security and convenience, the portal of DApp",
      ogx: "OGX: Synthetic assets issuance and trading protocol",
      ins3: "INS3: The next generation of fully decentralized insurance protocols"
    },
    text: {
      one: "Polkadot is a project that aims to break the ceiling of the blockchain industry. The cross-chain protocol is the appearance, but the solution to the capacity expansion of the blockchain is the fundamental. Polkadot has the potential to make the blockchain great again.",
      tow: "Kusama is a scalable development network built on nearly the same codebase as Polkadot. It’sdesigned for teams who want to move fast and innovate.",
      // three: "Breaking the barriers of assets between blockchains and building a public chain ecology of multi-currency integration.",
      four: "Phala:WEB3 Venture Capital long-term investment projectsBlockchain Confidentiality by Trusted Computing.",
      five: 'Digital currency aggregation payment platform,your blockchain "Paypal" for global payments.',
      six: "Voice social network based on WEB3 technology (data decentralized storage and cross-chainblockchain technology).",
      seven: "IBO: WEB3 Venture Capital Strategic Investment projectsSemi-decentralized exchange, abstracting the function of shelf on Token and transparent burn ofplatform Token and on-chain governance and gradually transition to decentralized exchange.",
      eight: "Decentralised fundraising platform in the Polkadot ecology,using strict decentralized KYC auditand DAO manage raised funds.",
      nine: "The deepest and most influential media platform in the Polkadot ecology.",
      tp: "The decentralized wallet will be the traffic entrance of the entire blockchain in the future and the entire DeFi infrastructure.",
      ogx: "Asset synthesis and issuance can synthesize numerous assets on the chain, and can build multiple applications such as spot trading and futures trading.",
      ins3: "INS3.FINANCE is a completely decentralized insurance protocol: DEFI + NFT + Oracle + Cross-chain model for a major crypto insurance ecosystem financial innovation."
    },
  },
  //研究
  research: {
    title: "Research",
    content: " The research team of WEB3 Venture Capital mainly looks for the wealth opportunities of the frontier of WEB3.0 technology landing in real life, and considers the rebalance of business, ethics, law and other issues.",
    span: "Visit the Research Wiki",
    right: {
      one: "WEB3 Venture Caplital Research is conducted in collaboration with various projects and our investingresearch groups. The team is primarily located in Singapore.",
      tow: "Our research focues on the areas of:",
      three: "Trusted Compudation and privacy protection algorithm.",
      four: "Decentralized blockchain networks and globally secure p2p payment networks.",
      five: "The next social network and social economy model.",
      six: 'Regulatory decentralised fundraising model and next-generation cryptoasset trading platform.',
      seven: "Decentralized non-collateralized small loans on the chain."
    },
    papers: 'Papers',
    blogs: 'Blogs',
    PapersTitle: {
      one: 'Confidencial data exchange protocol',
      tow: 'Digital currency aggregation payment platform',
      three: 'Voice social network based on WEB3 technology',
      four: 'Semi-decentralized exchange',
      five: 'Decentralised fundraising platform'
    },
    bottom: {
      head: 'Meet the Research Team',
      title: 'Peter Czaban',
      content: 'Chief Technology Officer'
    },
    click: 'View article'
  },
  //新闻
  news: {
    title: ' WEB3 Venture Capital and Listen project have partnered strategically',
    p: 'Wednesday, April 23,2020',
    newsTitle: 'NEWS',
    forDate: {
      ins3: 'Thursday, April 1, 2021',
      one: 'Wednesday, April 23,2020',
      tow: 'Tuesday, March 22,2020',
      three: 'Thursday, March 17,2020',
      four: 'Thursday, March 12,2020',
      five: 'Monday, February 11,2020'
    },
    forTitle: {
      ins3: "Web3 Venture Capital has successfully invested decentralized insurance protocol INS3.FINANCE",
      one: 'WEB3 Venture Capital and Listen project have partnered strategicallys',
      tow: 'WEB3 Venture Capital invest Phala.Network',
      three: 'WEB3 Venture Capital fundraising success in one week',
      four: 'WEB3 Venture Capital start to raise',
      five: 'WEB3 Venture Capital registered successfully',
    },
    click: 'View article'
  }
};